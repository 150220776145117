import React from 'react'

const UserDetails = () => {
  return (
    <>
        <h1>ユーザー詳細</h1>
    </>
  )
}

export default UserDetails