import React from 'react'

const Rankings = () => {
  return (
    <div className="w-full">
        <h1 className="m-auto">ランキング表示画面</h1>
    </div>
  )
}

export default Rankings