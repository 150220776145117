import React from 'react'

const QuizReview = () => {
  return (
    <>
        <h1>復習</h1>
    </>
  )
}

export default QuizReview